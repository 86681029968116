<template>
  <div class="login">
    <img src="../assets/images/home_bg_cclor@2x.png" alt="" class="position-absolute w-fill h-fill">
    <div class="login-box-container mg-b-80 position-absolute">
      <div class="login-title">
        <img src="../assets/images/icon_logo@2x.png" class="w-110">
      </div>
      <div class="mg-b-80 c-96">做笔记、背书、背单词就用闪记卡</div>
      <div class="login-container">
        <div class="login-container-title">
          <img src="../assets/images/icons_communication_iPhone_light_wechat_28pt_filled@2x.png"
            class="w-24 h-24 mg-r-8" />
          <div>微信扫一扫登录网页版</div>
        </div>
        <div class="login-container-code">
          <div id="code" v-if="qrcodeUrl">
            <vue-qr :text="qrcodeUrl" :size="264" :margin="0"></vue-qr>
          </div>
          <div v-else><i class="el-icon-loading fs-40"></i></div>
        </div>
        <div class="login-container-agreement">
          <img src="../assets/images/icons_filled_toggle_PC_light_tick-circle_24pt@2x.png" />
          <div class="agreement-text">
            同意
            <span>《用户协议》</span>和
            <span>《隐私协议》</span>
          </div>
        </div>
      </div>
      <div class="to-index" >
        <span @click="toIndex">产品介绍</span><span class="mg-l-10 mg-r-10">|</span><span @click="toHelper">使用手册</span>
      </div>
    </div>
    <BeiAnFooter></BeiAnFooter>
  </div>
</template>

<script>
import BeiAnFooter from '../components/common/BeiAnFooter.vue'
import VueQr from 'vue-qr'
import request from '../libs/api.request'
import { showMessage } from '../libs/message'
export default {
  components: {
    VueQr,
    BeiAnFooter
  },
  data() {
    return {
      qrcodeUrl: '',
      loginCode: '',
      timer: null,
      timer1:null
    }
  },
  methods: {
    toIndex() {
      this.$router.push('/')
    },
    toHelper() {
      window.open("https://amazenote.cn/");
    },
    initLogin() {
      request.request({ url: '/user/loginCode' }).then(res => {
        this.qrcodeUrl = res.qrCodeTxt
        this.loginCode = res.code
        this.getScanCodeResult()
      })
    },
    getScanCodeResult() {
      request.request({
        url: '/user/scanCodeResult', params: {
          code: this.loginCode
        }
      }).then(res => {
        if (res.status === 1) {
          clearTimeout(this.timer)
          this.setTimer(1000)
        } else if (res.status === 2) {
          this.$store.commit('setUserInfo', res.userInfo)
          showMessage({ message: '登录成功', type: 'success' })
          clearInterval(this.timer)
          this.$cookies.set('authToken', res.authorization, 86400 * 90 * 7);
          this.timer1 = setTimeout(() => {
            this.$router.push('/home/studyPlatform/cardAll')
          }, 500)
        } else {
          this.initLogin()
        }
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
    // 轮询接口
    setTimer(delay) {
      this.timer = setTimeout(() => {
        this.getScanCodeResult()
      }, delay)
    }
  },
  created() {
    this.initLogin()
    if(this.$route.query.useOrigin && this.$route.query.useOrigin === 'pc') {
      localStorage.setItem('useOrigin',this.$route.query.useOrigin)
    }
  },
  mounted() {
    const authToken = this.$cookies.get('authToken')
    if (authToken !== null && authToken.length > 0) {
      this.$router.push('/home')
    }
  },
  destroyed() {
    clearTimeout(this.timer)
    clearTimeout(this.timer1)
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;

  .login-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 360px;
    .to-index {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }

    .login-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      // line-height: 24px;
    }

    .login-container {
      width: 360px;
      padding: 32px 48px;
      border-radius: 12px;
      background-color: rgba(255,255,255,0.6);
      display: flex;
      flex-direction: column;
      align-items: center;

      .login-container-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        color: rgba(0, 0, 10, 0.6);
        margin-bottom: 32px;
      }

      .login-container-code {
        width: 264px;
        height: 264px;
        background: #ffffff;
        border-radius: 12px;
        border: 1px solid rgba(32, 32, 32, 0.1);
        padding: 12px;
         display: flex;
          justify-content: center;
          align-items: center;

        #code {
          height: 100%;
        }
      }

      .login-container-agreement {
        display: flex;
        margin-bottom: 25px;
        margin-top: 26px;

        .agreement-text {
          font-size: 16px;
          white-space: nowrap;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        span {
          color: $theme-color;
        }
      }

      .login-container-pev {
        position: relative;
        width: 100%;
        height: 1px;
        background: rgba(32, 32, 32, 0.06);

        .pev-text {
          position: absolute;
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
          width: 45px;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          background-color: #fff;
          color: rgba(32, 32, 32, 0.18);
        }
      }

      .login-qq {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid rgba(32, 32, 32, 0.16);
        margin-top: 22px;

        &:hover {
          cursor: pointer;
          border-color: #248cf9;
        }

        .qq-img {
          width: 24px;
          height: 24px;
        }

        .qq-text {
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
