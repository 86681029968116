<template>
  <div class="beian-footer c-9">
    <div class="mg-t-5 text-align-center fs-12 pd-5">
      深圳市惊叹科技有限公司 <a class="c-9" style="text-decoration: none" target="_blank" href="https://beian.miit.gov.cn/">{{ beian }}</a>
    | <img src="https://amazing-resources.oss-cn-shenzhen.aliyuncs.com/default/16711692081860_.pic.png" style="width: 16px;" />粤公网安备 44030302002417号
    </div>
    <div class="text-align-center fs-12 pd-5">地址：深圳市罗湖区桂园街道人民桥社区和平路3001号鸿隆世纪广场C座10E5</div>
    <div class="text-align-center fs-12 pd-5">联系方式：amazingcompany@163.com | 电话：17388758327</div>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        beian: '粤ICP备2022002259号',
      }
    }
  }
</script>
<style lang="scss" scoped>
  .beian-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }
</style>
